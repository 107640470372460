<template>
  <router-view/>
</template>

<style lang="scss">
html, body {
  position: relative;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 0.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $white;
  overflow: hidden;
}
* {
  box-sizing: border-box;
}
#app {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: $offBlack;
}

.isShowing {
  opacity: 1 !important;
}

.transition {
  transition: all 0.4s ease-in-out;

  &__page {
    transition: opacity 1s ease-in-out;
  }
}
</style>
