
import { defineComponent, onMounted, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { delay } from '@/utils/async';

export default defineComponent({
  name: 'HomeView',
  setup() {
    const isShowing = ref<boolean>(false)

    onMounted(async () => {
      document.title = 'CS27 Studios | Home'
      await delay(500)
      isShowing.value = true
    })

    onBeforeRouteLeave(async () => {
      isShowing.value = false
      await delay(1000)
    })

    return {
      isShowing
    }
  }
})
