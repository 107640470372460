
import { defineComponent, onMounted, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { delay } from '@/utils/async'

export default defineComponent({
  name: 'CrossbarCards',
  setup() {
    const isShowing = ref<boolean>(false)
    
    onMounted(async () => {
      document.title = 'Crossbar Cards'
      await delay(500)
      isShowing.value = true
    })

    onBeforeRouteLeave(async () => {
      isShowing.value = false
      await delay(1000)
    })

    return {
      isShowing
    }
  }
})
